import React from 'react';

const DownTime = () => {
    return (<div id="body" className="status">
        <h1>Bonbon user portal is down for maintenance.</h1>
        <h2>Don't worry! We'll be back soon.</h2>
    </div>);
}

export default DownTime;
