import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Logout from './Logout';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

const Header = () => {
    const theme = useTheme();

    return (<Box sx={{
        backgroundColor:'#FFF',
        boxShadow: `${theme.spacing(0, 3, 7, 4)} #CCC`,
        py: theme.spacing(5),
    }}>
    <Container>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box component="h2" sx={{m: '0'}}>
                <Link
                    component={RouterLink}
                    sx={{
                        display: 'block',
                        textDecoration: 'none',
                    }}
                    to="/"
                >
                    <img alt="Bonbon" width="100px" src="/bonbon_logo.png"/>
                </Link>
            </Box>
            <Logout/>
        </Stack>
    </Container>
    </Box>);
}

export default Header;
