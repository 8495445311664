import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "@mui/material/styles";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Logout = () => {
    const {
        isAuthenticated,
        user,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
    } = useAuth0();

    const theme = useTheme();

    useEffect(() => {
        if (!user) {
            getAccessTokenSilently();
        }
    }, [getAccessTokenSilently, user]);

    return (<Stack direction="row" alignItems="center" spacing={5}>
        {isAuthenticated
            ? <React.Fragment>
                <Typography component="span" variant="body1" sx={{
                    [theme.breakpoints.down('sm')]: {
                        display: 'none',
                    },
                }}>Welcome, {user.email}</Typography>
                <Button
                    variant="contained"
                    size="medium"
                    onClick={() => logout({ returnTo: window.location.origin })}
                >Log out</Button>
            </React.Fragment>
            : <Button
                variant="contained"
                size="medium"
                onClick={() => loginWithRedirect()}
            >Log In</Button>
        }
    </Stack>);
    
}

export default Logout;
