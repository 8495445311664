import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Tos = () => {
    return (<Container disableGutters={true} sx={{
            borderRadius: '5vmin',
            boxShadow: '-.5vmin .5vmin 2vmin .8vmin #DDD',
            my: '4rem',
        }}
        >
        <Box sx={{
            backgroundColor:'#F8F9FC',
            borderRadius: '5vmin 0 0 5vmin',
            width: {xs: '100%', md: '50%'},
            mx: '0',
            py: '2rem',
        }}>
            <Typography variant="h4">Bonbon Terms of Service</Typography>
        </Box>
    </Container>);
}

export default Tos;
