import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import Link from '@mui/material/Link';
import Container from '@mui/material/Container';

const Footer = () => {
    // const theme = useTheme();

    return (<Container>
        {/*<p>
            <Link component={RouterLink} to="/tos">Terms of Service</Link>
        </p>*/}
        <p>For more details on Bonbon&apos;s approach to privacy and information on other request that Bonbon supports, please refer to our <a href="https://bonbon.tech/privacy-policy/">Privacy Policy</a></p>
    </Container>);
};

export default Footer;
