import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Formik, Form } from 'formik';
import environments from './environments';
import * as yup from 'yup';
import axios from 'axios';

const environment = process.env.REACT_APP_ENVIRONMENT || "development";

const ProfileForm = () => {
    const [submitting, setSubmitting] = useState(false);
    const [userMetadata, setUserMetadata] = useState(null);
    const navigate = useNavigate();
    const {
        user,
        logout,
        getAccessTokenSilently,
    } = useAuth0();
    const theme = useTheme();
    /*
    const [user] = useState({ email: 'test@email.com' });
    const [userMetadata, setUserMetadata] = useState({
        name: 'User Name',
        gender: 'Man',
        zip_code: '12345',
        date_of_birth: '1987-06-05',
    });
    const [logout] = useState(() => '');
    const [getAccessTokenSilently] = useState(() => '');
    */

    const overwrite = window.location.href.includes('finalize');
    const changeAccount = window.location.href.includes('profile');
    const auth0Domain = environments[environment].REACT_APP_AUTH_DOMAIN;
    const genders = [
        '',
        'Female',
        'Male',
        'Transgender',
        'Non-Binary/non-conforming',
        'Prefer not to answer'
    ];
    const validationSchema = yup.object({
        first_name: yup
            .string('First Name *')
            .required('First name is required')
            .matches(/\S+/, 'First name is required'),
        zip_code: yup
            .string('Zip Code (optional)')
            .matches(/^\d{5}(-\d{4})?$/, '5 or 9 digit zip code 12345[-1234]'),
        date_of_birth: yup
            .date('Date of Birth (optional)')
            .nullable()
            .notRequired(),
    });

    const metadataSubmit = (user_metadata) => {
        setSubmitting(true);
        user_metadata.date_of_birth = user_metadata.date_of_birth &&
            user_metadata.date_of_birth.format('YYYY-MM-DD');
        for (const [key, value] of Object.entries(user_metadata)) {
            if (value) {
                user_metadata[key] = value.trim();
            }
        }
        const updateUserMetadata = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: environments[environment].REACT_APP_AUDIENCE,
                    scope: 'read:current_user update:current_user_metadata',
                });
                await axios.patch(
                    `https://${auth0Domain}/api/v2/users/${user.sub}`,
                    { user_metadata },
                    { headers: { Authorization: `Bearer ${accessToken}` } },
                );
                await axios.put(
                    `${environments[environment].REACT_APP_BONBON_API}/api/v1/users/passwordlessMetaMerge`,
                    {},
                    { headers: { Authorization: `Bearer ${accessToken}` } },
                );
                overwrite ? navigate('/account'): navigate('/profile');
            } catch (e) {
                console.log(e.message);
            }
            setSubmitting(false);
        };
        updateUserMetadata();
    };

    useEffect(() => {
        const emptyUserMetadata = {
            first_name: '',
            last_name: '',
            gender: '',
            date_of_birth: null,
            zip_code: '',
        };
        const getUserMetadata = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: environments[environment].REACT_APP_AUDIENCE,
                    scope: 'read:current_user update:current_user_metadata',
                });
                const metadataResponse = await axios.get(
                    `https://${auth0Domain}/api/v2/users/${user.sub}`,
                    { headers: { Authorization: `Bearer ${accessToken}` } },
                );
                const { user_metadata } = metadataResponse.data;
                if (user_metadata && user_metadata.date_of_birth) {
                    user_metadata.date_of_birth = dayjs(user_metadata.date_of_birth);
                }
                setUserMetadata({ ...emptyUserMetadata, ...user_metadata });
            } catch (e) {
                console.log(e.message);
                console.log(e.stack);
            }
        };
        if (!overwrite) {
            if (user && user.sub) { getUserMetadata(); }
        } else {
            setUserMetadata(emptyUserMetadata);
        }
    }, [auth0Domain, overwrite, getAccessTokenSilently, user, user.sub]);

    if (userMetadata) {
        return (<Formik
            initialValues={userMetadata}
            validationSchema={validationSchema}
            onSubmit={metadataSubmit}
        >
            {props => (
                <Form>
                  <Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{py: theme.spacing(4)}}
                  >
                      <Typography component="span" variant="subtitle2">{user.email}</Typography>
                      {changeAccount &&
                          <Button
                            variant="text"
                            size="small"
                            onClick={() => logout({ returnTo: window.location.origin })}
                          >Not you?</Button>
                      }
                  </Stack>
                  <TextField
                    id="first_name"
                    name="first_name"
                    label="First Name *"
                    value={props.values.first_name}
                    onChange={props.handleChange}
                    error={props.touched.first_name && Boolean(props.errors.first_name)}
                    helperText={props.touched.first_name && props.errors.first_name}
                    sx={{mb: theme.spacing(5)}}
                  />
                  {/*
                  <TextField
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    value={props.values.last_name}
                    onChange={props.handleChange}
                    error={props.touched.last_name && Boolean(props.errors.last_name)}
                    helperText={props.touched.last_name && props.errors.last_name}
                    sx={{mb: theme.spacing(5), display: 'none'}}
                  />
                  */}
                  <FormControl>
                      <InputLabel id="gender-label">Gender</InputLabel>
                      <Select
                        id="gender"
                        labelId="gender-label"
                        name="gender"
                        label="Gender"
                        value={props.values.gender}
                        onChange={props.handleChange}
                        error={props.touched.gender && Boolean(props.errors.gender)}
                        sx={{mb: theme.spacing(5)}}
                      >
                            { genders.map(gender => (<MenuItem
                                    key={gender}
                                    value={gender}
                                >{gender || 'Gender (optional)'}</MenuItem>)) }
                      </Select>
                  </FormControl>
                  <TextField
                    id="zip_code"
                    name="zip_code"
                    label="Zip Code"
                    value={props.values.zip_code}
                    onChange={props.handleChange}
                    error={props.touched.zip_code && Boolean(props.errors.zip_code)}
                    helperText={props.touched.zip_code && props.errors.zip_code}
                    sx={{mb: theme.spacing(5)}}
                  />
                  {/*
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        id="date_of_birth"
                        name="date_of_birth"
                        label="Date of Birth"
                        format="mm/dd/yyyy"
                        value={props.values.date_of_birth}
                        onChange={value => props.setFieldValue("date_of_birth", value) }
                        error={props.touched.date_of_birth && Boolean(props.errors.date_of_birth)}
                        helperText={props.touched.date_of_birth && props.errors.date_of_birth}
                        renderInput={(params) => <TextField
                            {...params}
                            sx={{
                                '& & .MuiFormControl-root' : {
                                    color: '#F00',
                                    backgroundColor: '#F00',
                                    mb: theme.spacing(5),
                                    display: 'none',
                                }
                            }}
                        />}
                      />
                  </LocalizationProvider>
                  */}
                  <Button
                        variant="contained"
                        type="submit"
                        size="medium"
                        disabled={submitting}
                        sx={{
                            fontWeight: 'bold',
                            verticalAlign: 'center',
                        }}
                  >Complete your account<Typography sx={{pl: theme.spacing(2)}} component="span">&#x2794;</Typography></Button>
                  </Stack>
                </Form>
            )}
        </Formik>);
    } else {
        return (<ReactLoading type="balls" />);
    }
}

export default withAuthenticationRequired(ProfileForm, {
    onRedirecting: () => <ReactLoading type="balls" />,
});
