import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import { UserProvider } from './UserContext.js';
import { Auth0Provider } from "@auth0/auth0-react";
import environments from './environments';

const environment = process.env.REACT_APP_ENVIRONMENT || 'development';

let theme = createTheme({
  typography: {
    h1: {
      fontSize: '4rem',
      fontWeight: '800',
      lineHeight: 1,
    },
    h2: {
      fontSize: '3.25rem',
      fontWeight: '800',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '3rem',
      fontWeight: '700',
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '2rem',
      fontWeight: '600',
      lineHeight: 1.4,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: '800',
      lineHeight: 1.2,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: '500',
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.4,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.4,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.6,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.4,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 2.66,
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: 1,
    },
  },
  palette: {
    primary: {
      main: '#3443EF',
      dark: '#1F2BB2',
      light: '#4268F2',
      background: '#FAFAFA',
      border: '#E0E0E0',
      black: '#000',
      white: '#FFF',
    },
    secondary: {
      main: '#FA6815',
      dark: '#DB4002',
      light: '#FF8D16',
    },
    action: {
        hover: '#3443EF',
        hoverOpacity: 0.04,
        selected: '#3443EF',
        selectedOpacity: 0.08,
        focus: '#3443EF',
        focusOpacity: 0.12,
        focusVisible: '#3443EF',
        focusVisibleOpacity: 0.3,
        outlinedBorder: '#3443EF',
        outlinedBorderOpacity: 0.5,
    },
  },
  spacing: [0, 4, 8, 12, 16, 24, 32, 40, 48],
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: '#EEE',
                    minHeight: '100%',
                },
                html: {
                    minHeight: '100%',
                },
            },
        },
    },
});

theme = createTheme(theme, {
    typography: {
        button: theme.typography.h6
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: theme.spacing(1),
                    lineHeight: 1,
                    textTransform: 'none',
                },
                contained: {
                    backgroundColor: theme.palette.primary.main,
                },
                sizeSmall: {
                    padding: theme.spacing(2, 1),
                    typography: theme
                },
                sizeMedium: {
                    padding: theme.spacing(5, 3),
                },
                sizeLarge: {
                    padding: theme.spacing(6, 4),
                },
            },
        },
    },
});

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(<Auth0Provider
    domain={environments[environment].REACT_APP_AUTH_DOMAIN}
    clientId={environments[environment].REACT_APP_CLIENTID}
    redirectUri={window.location.origin + '/auth/callback'}
  >
  <UserProvider>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>
</UserProvider>
</Auth0Provider>);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
